<!-- @format -->

<template>
  <header class="h-16 2xl:px-10 bg-sky-600 text-white text-primary-content sticky top-0 shadow-md z-50 flex items-center">
    <div class="p-2 mx-auto text-left flex w-full items-center">
      <div class="text-lg md:text-3xl flex-1 text-white uppercase" @click="() => $router.push({ name: 'home' })">
        <span class="cursor-pointer">Dev <span class="text-lg">Market</span></span>
        <!-- <div class="flex h-12">
          <img src="@/assets/pandaisuite_logo.png" class="object-cover" />          
        </div> -->
      </div>
      <!-- <div class="text-xl lg:text-3xl flex-1 text-white"><span class="font-black">PANDAICART</span><span class="font-thin text-lg">public marketplace</span></div> -->
      <div v-if="!isAuthenticated" class="flex md:flex-row md:gap-2">
        <button class="hidden md:flex flex-row items-center bg-white text-sky-600 px-4 py-2 font-semibold rounded-full hover:bg-lime-500 hover:text-white transition ease-out duration-300" @click="createAccount">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4 font-semibold">
            <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15m3 0l3-3m0 0l-3-3m3 3H9" />
          </svg> &nbsp;

          Create Account
        </button>
        <button class="hidden md:flex flex-row items-center bg-white text-sky-600 px-4 py-2 font-semibold rounded-full hover:bg-lime-500 hover:text-white transition ease-out duration-300" @click="login">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4 font-semibold">
            <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15m3 0l3-3m0 0l-3-3m3 3H9" />
          </svg> &nbsp;

          Login
        </button>

        <!-- MOBILE HAMBURGER MENU -->
        <button class="flex flex-row md:hidden items-center text-white p-1 font-semibold rounded-md hover:bg-lime-500 hover:text-white transition ease-out duration-300" @click="onShowAuthMenu">
          <svg v-if="!showAuthMenu" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-8 h-8">
            <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5M12 17.25h8.25" />
          </svg>
          <svg v-else xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
            <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
          </svg>

        </button>
      </div>
      <template v-else>
        <div class="flex flex-row gap-2 items-center bg-white rounded-full pr-4 shadow-lg hover:bg-lime-200 transition ease-out duration-300">
          <div class="h-9 w-9 border border-white rounded-full flex overflow-hidden shadow-lg">
            <img :src="getUserData.profile_photo_url" class="object-cover shadow-md" />
          </div>
  
          <div class="text-sm font-bold cursor-pointer text-sky-600" @click="logoutAction">
            Logout
          </div>
        </div>
      </template>
    </div>
    <!-- Mobile Auth Menu -->
    <div :class="showAuthMenu ? 'absolute top-10 right-0 z-50 mt-6': 'hidden'" class="w-full bg-sky-600 flex-grow sm:flex sm:items-center sm:w-auto rounded-bl-lg rounded-br-lg">
      <div class="flex flex-row md:hidden justify-center mb-5 text-sm sm:flex-grow gap-2">
        <button class="flex flex-row items-center bg-white text-sky-600 px-4 py-2 font-semibold rounded-full hover:bg-lime-500 hover:text-white transition ease-out duration-300" @click="createAccount">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4 font-semibold">
            <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15m3 0l3-3m0 0l-3-3m3 3H9" />
          </svg> &nbsp;

          Create Account
        </button>
        <button class="flex flex-row items-center bg-white text-sky-600 px-4 py-2 font-semibold rounded-full hover:bg-lime-500 hover:text-white transition ease-out duration-300" @click="login">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4 font-semibold">
            <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15m3 0l3-3m0 0l-3-3m3 3H9" />
          </svg> &nbsp;

          Login
        </button>
      </div>
    </div>
  </header>
  <div v-if="layout === 'default'" class="flex flex-col lg:flex-row px-0 lg:px-4" @click="showAuthMenu = false">
    <aside class="w-full lg:w-1/4 h-fit md:h-screen sticky top-16 left-0 z-40 md:z-0">
      <slot name="sidebar"></slot>
    </aside>
    <div class="w-full">
      <slot></slot>
    </div>
  </div>
  <div v-else class="flex flex-col w-full" @click="showAuthMenu = false">
    <slot></slot>
  </div>
  <RegistrationForm v-model:showOn="showRegistForm" @onSuccess="onRegistrationCompleted" />
</template>
<script>
//import { checkAuth } from '@/data/pandai.auth';
// import FlatMiniButton from "@/components/FlatMiniButton.vue";
import RegistrationForm from "@/views/RegistrationForm.vue";
import { mapGetters, mapActions } from "vuex";
import { authUri } from "@/data/appdata";
export default {
  props: {
    layout: {
      type: String,
      default: "default",
    }
  },
  data() {
    return {
      test: null,
      showRegistForm: false,
      showAuthMenu: false,
    };
  },
  computed: {
    ...mapGetters(["getUserData", "getAuthToken", "isAuthenticated", "regState"]),
    authEndPoint() {
      return authUri;
    },
  },
  watch: {
   /*  getAuthToken: function (v) {
      console.log(v);
    }, */
    regState:function(v){        
        if(!v){
          this.showRegistForm = false;
        }
        this.showRegistForm = true;
      
    },
    getUserData(v) {
      if(v && this.showAuthMenu) {
        this.showAuthMenu = false;
      }
    }
  }, 
  methods: {
    
    ...mapActions(["logoutAction", "invokeRegister"]),
    onShowAuthMenu() {
      this.showAuthMenu = !this.showAuthMenu;
    },
    async onRegistrationCompleted(data){
      let token = data.token;
      let user = data.user;      
      await this.$store.dispatch('saveAuthToken', { token });
      await this.$store.dispatch('setUserdata', {data:user});
      this.showRegistForm = false;
      this.setUserData(user);
    },
    login() {
      //const authUri = 'http://pandaisuite.local/oauth/authorize_access?target=http://192.168.111.40:8080/pandai_auth/';
      /*       var w = 460;
            var h = 540;
            var left = (screen.width) - w;
            var top = (screen.height/2)-(h/2);
            return window.open(authUri, 'Login With PandaiSuite', 'toolbar=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width='+w+', height='+h+', top='+top+', left='+left); */
      return window.open(this.authEndPoint, "newwindow", "width=500, height=500");
    },
    createAccount() {
      //console.log('test2')
      return this.$store.dispatch('invokeRegister', {data: new Date()})
    }
  },
  /* async created() {
      const data = await checkAuth();
      console.log(data);
  }, */
  components: { RegistrationForm },
};
</script>
<style>
.v-enter-active,
.v-leave-active {
  transition: opacity 0.2s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
