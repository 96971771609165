import axios from 'axios';
const marketId = process.env.VUE_APP_MARKETPLACE_ID; //Public marketplace ID



export async function createAccount(data){
    try {
        return await axios.post("auth/create_account/" + marketId, data);        
    } catch (error) {
        //console.log('service catch: '+error.response.data);
        throw new Error(error.response.data);
    }   
}