<!-- @format -->

<template>
  <Slideout @closing="onClosing" v-model="onShow" title="Course Information" size="0">
    <div class="sm:p-2 md:p-4 lg:p-8 max-w-2xl bg-base-300" v-if="course">
      <CourseInfoBar :course="course"></CourseInfoBar>

      <div class="mt-4 p-4 bg-white/80 rounded-lg grid grid-cols-1 mx-2">
        <div class="flex items-center mr-10">
          <div
            class="w-10 h-10 flex items-center justify-center bg-sky-100 border border-sky-200 text-sky-600 rounded-full text-xl">
            <fa-icon :icon="['fa', 'school']"></fa-icon>
          </div>
          <div class="flex-1 ml-4">
            <div class="font-light text-base-500 border-b border-dashed border-base-200">
              Training Provider
            </div>
            <div class="text-lg font-semibold text-sky-600">{{ course.client.name }}</div>
          </div>
        </div>

        <div class="flex items-center">
          <div
            class="w-10 h-10 flex items-center justify-center bg-sky-100 border border-sky-200 text-sky-600 rounded-full text-xl">
            <fa-icon :icon="['fa', 'tag']"></fa-icon>
          </div>
          <div class="flex-1 ml-4 mr-10">
            <div class="font-light text-base-500 border-b border-dashed border-base-200">
              SSG Subsidy
            </div>
            <div class="text-lg font-semibold" :class="course.ssg_reference != null ? 'text-sky-600' : 'text-rose-600'">
              {{ course.ssg_reference != null ? "Available" : "Unavailable" }}
            </div>
          </div>
        </div>

        <!--<div class="flex items-center mx-2 py-4" v-if="course.ssg_reference">
          <div class="text-lg mx-auto font-semibold text-sky-600">
            <FlatMiniButton backgroundColor="bg-lime-500 hover:bg-lime-600">
              <div class="py-1 px-3 text-md">Check Subsidy Amount</div>
            </FlatMiniButton>
          </div>
        </div>-->

      <div class="grid sm:grid-cols-2 mx-2 mt-4 flex-wrap gap-4">
        <div class="p-4 bg-white/20 border border-white rounded-xl flex flex-col mb-4">
          <CardListTile title="Course Description">
            {{ course.description }}
          </CardListTile>
          <CardListTile title="Course Objective"> {{ course.objective }} </CardListTile>
          <CardListTile title="Course Content"> {{ course.content }} </CardListTile>
          <CardListTile title="Minimal Education">
            {{ course.minimum_education_requirement }}
          </CardListTile>
        </div>
        <div class="p-4 bg-white/20 border border-white rounded-xl flex flex-col mb-4">
          <CardListTile title="Instruction Medium">
            {{ course.instruction_medium }}
          </CardListTile>
          <CardListTile title="Training Mode"> {{ course.training_mode }} </CardListTile>
          <CardListTile title="Course Duration"> {{ course.duration }} H</CardListTile>
          <CardListTile title="Entry Requirements">
            {{ course.entry_requirement }}
          </CardListTile>
          <CardListTile title="Expected Outcome"> {{ course.outcome }} </CardListTile>
        </div>
      </div>

      <div class="flex w-full justify-center p-8 space-x-4">
        <FlatMiniButton backgroundColor="bg-indigo-400">
          <div class="py-1 px-4 text-lg" @click.stop="onInterest">
            Register Interest
          </div>
        </FlatMiniButton>

        
        <FlatMiniButton backgroundColor="bg-indigo-400">
          <div class="py-1 px-4 text-lg" @click="onViewSchedule">
            View Schedules
          </div>
        </FlatMiniButton>
      </div>
    </div>
    </div>
  </Slideout>
</template>
<script>
import { defineComponent } from "vue";
import FlatMiniButton from '@/components/FlatMiniButton.vue';
import CourseInfoBar from "@/components/CourseInfoBar.vue";
import CardListTile from "@/components/CardListTile.vue";
import { registerInterest } from "@/data/courses.data";
export default defineComponent({
  props: {
    showOn: {
      type: Boolean,
      default: false,
    },
    course: Object,
  },
  data() {
    return {};
  },
  methods: {
    onClosing() { },
    onViewSchedule() {
      this.onShow = false;
      this.$emit("onViewSchedule", this.course);
    },
    async onInterest() {
      this.onShow = false;
      this.$swal.fire({
        title: "Register Your Interest",
        html: `
                <label>Your Email:</label><br>
                <input id="email" class="swal2-input" placeholder="Email">
            `,
        input: "textarea",
        inputLabel: "Your Message",
        inputAttributes: {
          placeholder: "Your Message",
          autocapitalize: "off",
        },
        showCancelButton: true,
        confirmButtonText: "Submit",
        showLoaderOnConfirm: true,
        preConfirm: async (text) => {
          let email = document.getElementById("email").value;
          let interestData = {
            email: email,
            data: text,
            courseid: this.course.id,
          }
          try {
            const response = await registerInterest(interestData);
            this.notifySuccess(response);
          } catch (error) {
            let errorResponse = error.response.data;
            this.notifyError(errorResponse)
          }
          /* console.log(email)
          console.log(text) */
        },
      });
    },
  },
  computed: {
    onShow: {
      get() {
        return this.showOn;
      },
      set(v) {
        return this.$emit("update:showOn", v);
      },
    },
  },
  components: {
    CourseInfoBar,
    FlatMiniButton,
    CardListTile
  },
});
</script>
<style lang=""></style>
