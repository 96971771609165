<template>
    <slideout v-model="onShow" @closing="resetReg" title="Registration Form" size="0" class="relative">
        <div class="absolute h-full w-full bg-black/40 z-50 flex items-center justify-center" v-if="isLoading">
            <div class="w-20 bg-white h-20 rounded-full">
                <Vue3Lottie :animationData="Loading" class="flex" />
            </div>

        </div>
        <div class="py-4 px-20 text-lg text-center text-sky-600 font-light border-b border-sky-700 border-dashed mb-8">
            Create PandaiSuite Learner Account
        </div>
        <div class="pb-8 px-20">
            <div class="bg-rose-200 text-rose-800 rounded-lg px-4 py-2 flex" v-if="requestError">
                <div class="px-2">
                    <fa-icon icon="circle-exclamation"></fa-icon>
                </div>
                <div class="capitalize">{{ requestError }}</div>
            </div>
        </div>

        <div class="grid grid-cols-1 lg:grid-cols-3 px-4 bg-neutral-200 gap-4">
            <div class="w-full p-2 lg:p-4bg-white border-gray-300 border-neutral-200">
                <div>
                    <label for="name" class="block">Email Address</label>
                    <input name="email" type="email" :maxLength="240"
                        class="border-gray-300 w-full h-10 px-3 mb-5 rounded-md disabled:bg-neutral-100"
                        placeholder="Email Address" v-model="form.email" />
                </div>


                <div>
                    <label for="name" class="block">Choose Password</label>
                    <input name="password" type="password" class="border-gray-300 w-full h-10 px-3 mb-5 rounded-md"
                        placeholder="password" :maxLength="150" v-model="form.password" />
                </div>

                <div>
                    <label for="name" class="block">Confirm Password</label>
                    <input name="confirm_password" type="password"
                        class="border-gray-300 w-full h-10 px-3 mb-5 rounded-md" placeholder="confirm password"
                        :maxLength="150" v-model="confirm_password" />
                </div>

                <div>
                    <label for="name" class="block">Name <span class="font-thin text-gray-400">(As in
                            ID)</span></label>
                    <input name="name" type="text" class="border-gray-300 w-full h-10 px-3 mb-5 rounded-md"
                        placeholder="Name as in ID" :maxLength="240" v-model="form.name" />
                </div>

                <div class="mb-4">
                    <label for="id_type">ID Type </label>
                    <select
                        class="form-select appearance-none block w-full px-3 py-2 text-base font-normal text-gray-700 bg-white/75 bg-clip-padding bg-no-repeat border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                        aria-label="Default select example" v-model="idTypeSelection">
                        <option selected>Select type of ID</option>
                        <option value="NRIC">NRIC</option>
                        <option value="FIN">FIN</option>
                        <option value="OTHER">OTHER</option>
                    </select>
                </div>

                <div v-if="idTypeSelection == 'OTHER'">
                    <label for="other_id_type" class="block">Specify Other ID </label>
                    <input name="other_id_type" type="text" class="border-gray-300 w-full h-10 px-3 rounded-md mb-4"
                        placeholder="Specify ID Type" v-model="form.id_type" />
                </div>

                <div>
                    <label for="id_number" class="block">ID Number </label>
                    <input name="id_number" type="text" class="border-gray-300 w-full h-10 px-3 rounded-md mb-4"
                        placeholder="ID Number" v-model="form.id_number" />
                </div>

                <div class="mb-4">
                    <label for="date_of_birth" class="block">Date Of Birth</label>
                    <Datepicker v-model="form.date_of_birth" autoApply :enableTimePicker="false" :maxDate="new Date()"
                        format="MM-dd-yyyy" />

                </div>



            </div>

            <div class="w-full p-2 lg:p-4bg-white border-gray-300 border-neutral-200">
                <div class="mb-4">
                    <label for="gender">Gender </label>
                    <select
                        class="form-select appearance-none block w-full px-3 py-2 text-base font-normal text-gray-700 bg-white/75 bg-clip-padding bg-no-repeat border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                        aria-label="Default select example" name="gender" v-model="form.gender">
                        <option value="male">Male</option>
                        <option value="female">Female</option>
                    </select>
                </div>
                <div class="mb-4">
                    <label for="id_type">Nationality </label>
                    <select
                        class="form-select appearance-none block w-full px-3 py-2 text-base font-normal text-gray-700 bg-white/75 bg-clip-padding bg-no-repeat border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                        aria-label="Default select example" v-model="form.nationality">
                        <option value="Singaporean">Singaporean</option>
                        <option value="Permanent Resident">Permanent Resident</option>
                        <option value="Foreigner">Foreigner</option>
                    </select>
                </div>

                <div class="mb-4">
                    <label for="id_type">Race </label>
                    <select
                        class="form-select appearance-none block w-full px-3 py-2 text-base font-normal text-gray-700 bg-white/75 bg-clip-padding bg-no-repeat border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                        aria-label="Default select example" v-model="raceSelection">
                        <option value="Chinese">Chinese</option>
                        <option value="Malay">Malay</option>
                        <option value="Indian">Indian</option>
                        <option value="OTHER">OTHER</option>
                    </select>
                </div>
                <div v-if="raceSelection == 'OTHER'">
                    <label for="other-race" class="block">Other Race</label>
                    <input name="other-race" type="text" class="border-gray-300 w-full h-10 px-3 mb-5 rounded-md"
                        placeholder="Other race, please specify" v-model="form.race" />
                </div>

                <div>
                    <label for="mobile" class="block">Mobile Number</label>
                    <input name="mobile" type="text" :maxLength="30"
                        class="border-gray-300 w-full h-10 px-3 mb-5 rounded-md" placeholder="+65"
                        v-model="form.mobile_number" />
                </div>

                <div class="mb-4">
                    <label for="highest-qualification">Hightest Qualification</label>
                    <select
                        class="form-select appearance-none block w-full px-3 py-2 text-base font-normal text-gray-700 bg-white/75 bg-clip-padding bg-no-repeat border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                        aria-label="Default select example" v-model="qualification">
                        <option value="Masters &amp; Doctorate Equivalent">
                            Masters &amp; Doctorate Equivalent
                        </option>
                        <option value="Postgraduate Diploma/Certificate (Excluding Master &amp; Doctorate)">
                            Postgraduate Diploma/Certificate (Excluding Master &amp; Doctorate)
                        </option>
                        <option value="Bachelor or Equivalent">Bachelor or Equivalent</option>
                        <option value="Professional Qualification &amp; Other Diploma">
                            Professional Qualification &amp; Other Diploma
                        </option>
                        <option value="Polytechnic Diploma">Polytechnic Diploma</option>
                        <option value="Post-Secondary (Non-Tertiary) : General &amp; Vocational">
                            Post-Secondary (Non-Tertiary) : General &amp; Vocational
                        </option>
                        <option value="GCE 'O' Level">GCE "O" Level</option>
                        <option value="Secondary">Secondary</option>
                        <option value="Primary">Primary</option>
                        <option value="OTHER">OTHER</option>
                    </select>
                </div>
                <div v-if="qualification == 'OTHER'">
                    <label for="other-race" class="block">Other Qualification</label>
                    <input name="other-race" type="text" :maxLength="240"
                        class="border-gray-300 w-full h-10 px-3 mb-5 rounded-md"
                        placeholder="Other qualification, please specify" v-model="form.highest_qualification" />
                </div>
                <div>
                    <label for="postal-code" class="block">Occupation</label>
                    <input name="occupation" type="text" :maxLength="100"
                        class="border-gray-300 w-full h-10 px-3 mb-5 rounded-md" placeholder="Occupation"
                        v-model="form.occupation" />
                </div>
                <div>
                    <label for="postal-code" class="block">Education</label>
                    <input name="school-name" type="text" :maxLength="100"
                        class="border-gray-300 w-full h-10 px-3 mb-5 rounded-md" placeholder="Education"
                        v-model="form.schoolname" />
                </div>
            </div>












            <div class="w-full  p-2 lg:p-4 bg-white border-gray-300 border-neutral-200">
                <div class="mb-4">
                    <label for="payment-method">How Do You Plan To Make Payment </label>
                    <select
                        class="form-select appearance-none block w-full px-3 py-2 text-base font-normal text-gray-700 bg-white/75 bg-clip-padding bg-no-repeat border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                        name="payment-method" aria-label="Default select example" v-model="form.choice_of_payment">
                        <option selected>Select Payment Method</option>
                        <option value="SkillsFuture Credit">SkillsFuture Credit</option>
                        <option value="PSEA">PSEA</option>
                        <option value="Bank Transfer">Bank Transfer</option>
                        <option value="Credit/Debit Card">Credit/Debit Card</option>
                        <option value="Cheque">Cheque</option>
                    </select>
                </div>
                <div class="mb-4">
                    <label for="bio" class="block">Bio</label>
                    <textarea v-model="form.bio" name="bio" id="" class="w-full rounded border-gray-300" rows="2"
                        :maxLength="2000"></textarea>
                </div>
                <div class="mb-4">
                    <label for="billing-address" class="block">Billing Address</label>
                    <textarea v-model="form.billing_address" name="billing-address" id=""
                        class="w-full rounded border-gray-300" rows="2" :maxLength="240"></textarea>
                </div>

                <div>
                    <label for="postal-code" class="block">Postal Code</label>
                    <input name="postal-code" type="text" :maxLength="20"
                        class="border-gray-300 w-full h-10 px-3 mb-5 rounded-md" placeholder="Postal Code"
                        v-model="form.post_code" />
                </div>
                <div class="p-4 flex justify-center">
                    <FlatMiniButton backgroundColor="bg-sky-600 hover:bg-sky-500" @click="doRegister">
                        <div class="py-1 px-4 text-md">Create Account</div>
                    </FlatMiniButton>
                </div>
                <!--      <div class="">
          <input
            type="file"
            class="hidden"
            ref="courseImage"
            @change="updateImagePreview"
          />
          <p>
            Profile Photo <span class="text-sm text-neutral-400">Click To Change</span>
          </p>
          <div
            class="border cursor-pointer border-white hover:shadow"
            @click="selectImage"
          >
            <div class="h-[150px] flex p-2 border hover:border-lime-500">
              <div
                class="bg-gray-300 h-full w-full flex items-center justify-center overflow-hidden"
              >
                <fa-icon
                  v-if="!previewImageUrl"
                  icon="image"
                  class="text-2xl text-white"
                ></fa-icon>
                <img v-else :src="previewImageUrl" alt="" class="object-cover" />
              </div>
            </div>
          </div>
        </div> -->
            </div>













        </div>
    </slideout>
</template>
<script>
import { defineComponent } from 'vue';
import { createAccount } from '@/data/auth.service';
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import FlatMiniButton from '@/components/FlatMiniButton.vue';
import Loading from "@/assets/lotties/hourglass_loading.json";
import { Vue3Lottie } from "vue3-lottie";
import "vue3-lottie/dist/style.css";
export default defineComponent({
    props: {
        showOn: {
            type: Boolean,
            default: false,
        }
    },
    data() {
        return {
            Loading,
            isLoading: false,
            idTypeSelection: null,
            raceSelection: null,
            qualification: null,
            otherQualification: null,
            requestError: null,
            confirm_password: 'password',
            form: {
                /* id_number: 'NRIC',
                id_type: '052645815',
                name: 'Registration Test',
                email: 'regtest@email.com',
                password: 'password',
                date_of_birth: new Date(),
                gender: 'male',
                nationality: 'singaporean',
                race: 'chinese',
                mobile_number: '+65.3326458881',
                highest_qualification: 'Primary',
                choice_of_payment: 'Bank Transfer',
                billing_address: 'Jurong St. 234 XXX',
                post_code: '132456',
                occupation: 'Web Developer',
                schoolname: 'Some School',
                bio: 'Nothing special about me',
                image: null, */
                id_number: 'null',
                id_type: null,
                name: null,
                email: null,
                password: null,
                date_of_birth: null,
                gender: null,
                nationality: null,
                race: null,
                mobile_number: null,
                highest_qualification: null,
                choice_of_payment: null,
                billing_address: null,
                post_code: null,
                occupation: null,
                schoolname: null,
                bio: null,
                image: null,
            }
        }
    },
    methods: {
        async doRegister() {
            if (this.validateData() == true) {
                try {
                    this.requestError = null;
                    this.isLoading = true;
                    let response = await createAccount(this.form);                    
                    this.isLoading = false;
                    let data = response.data;
                    return this.$emit('onSuccess', data)                    
                } catch (error) {
                    this.isLoading = false;
                    this.requestError = error;
                    console.log(error);
                }
            }

        },
        validateData() {
            const validateEmail = (email) => {
                if (!email)
                    return false;
                /* eslint-disable no-useless-escape */
                return email.match(
                    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                );
            };

            if (!validateEmail(this.form.email)) {
                this.requestError = 'Please provide valid email';
                return false;
            }

            if (!this.form.password) {
                this.requestError = 'Please choose a password for your account';
                return false;
            }

            if (!this.passConfirmedMatch) {
                this.requestError = 'Password confirmation not match';
                return false;
            }

            let fields = [
                //'email',
                //'password',
                'name',
                'id_type',
                'id_number',
                'date_of_birth',
                'gender',
                'nationality',
                'race',
                'mobile_number',
                'highest_qualification',
                'billing_address',
                'choice_of_payment',
                'post_code',
                'occupation',
                //'schoolname',
                //'bio',
                //'image',
            ];
            let findEmpty = fields.find((f) => {
                return !this.form[f];
            })
            if (findEmpty) {
                this.requestError = findEmpty.replaceAll('_', ' ') + ' field is required';
                return false;
            }            
            return true;
        },
        resetReg() {
            return this.$store.dispatch('invokeRegister', { data: null })
        }
    },
    watch: {
        idTypeSelection: function (v) {
            if (v != 'OTHER') {
                this.form.id_type = v;
            } else {
                this.form.id_type = null;
            }
        },
        raceSelection: function (v) {
            if (v != 'OTHER') {
                this.form.race = v;
            } else {
                this.form.race = null;
            }
        },
        qualification: function (v) {
            if (v != 'OTHER') {
                this.form.highest_qualification = v;
            } else {
                this.form.highest_qualification = null;
            }
        }
    },
    computed: {
        passConfirmedMatch() {
            return this.confirm_password == this.form.password;
        },
        onShow: {
            get() {
                return this.showOn;
            },
            set(v) {
                return this.$emit('update:showOn', v);
            }
        }
    },
    components: {
        Datepicker,
        FlatMiniButton,
        Vue3Lottie
    }
})
</script>
<style>
</style>